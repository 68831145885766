// Import the functions you need from the SDKs you need
// src/firebase.js
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCbYM5XSumqhVLs6gGrVtB5q8UVKUVqJL0",
  authDomain: "superclube-b-r-f-e0e80z.firebaseapp.com",
  projectId: "superclube-b-r-f-e0e80z",
  storageBucket: "superclube-b-r-f-e0e80z.firebasestorage.app",
  messagingSenderId: "1060661113673",
  appId: "1:1060661113673:web:87577beb49a7afd1356098"
};

// Inicializa o Firebase
const app = initializeApp(firebaseConfig);

// Inicializa o Firestore
const db = getFirestore(app);

export { db };
