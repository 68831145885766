// src/Confirmacao.js
import React from 'react';

function Confirmacao() {
  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>Os termos foram aceitos com sucesso!</h1>
      <p>Em 24h, seu acesso será normalizado.</p>
    </div>
  );
}

export default Confirmacao;