// src/TermosDeUso.js
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from './firebase';
import './TermosDeUso.css';

function TermosDeUso() {
  const [formData, setFormData] = useState({
    id: '',
    email: '',
    nomeCompleto: '',
    aceitouTermos: false,
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.id) newErrors.id = 'ID é obrigatório.';
    if (!formData.email) newErrors.email = 'Email é obrigatório.';
    if (!formData.nomeCompleto) newErrors.nomeCompleto = 'Nome completo é obrigatório.';
    if (!formData.aceitouTermos) newErrors.aceitouTermos = 'Você deve aceitar os termos de uso.';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    console.log('teste');
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setIsLoading(true);
      try {
        await addDoc(collection(db, 'aceites'), {
          ...formData,
          timestamp: Timestamp.now(), // Adiciona a data e hora do aceite
        });
        setIsLoading(false);
        navigate('/confirmacao'); // Redireciona para a página de confirmação
      } catch (error) {
        console.error('Erro ao salvar no Firebase:', error);
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="termos-container">
       <h1 className="title">  Temos um recado importante: </h1>
      <p>Foi identificado um pagamento em duplicidade na sua conta, referente a campanha BECEL TOPs de JUNHO-2024. 
      <br/><br/>Este valor será descontado do seu saldo atual. Verifique o seu extrato na plataforma.
      <br/><br/>Se o seu saldo ficar negativo, não se preocupe! Os próximos ganhos de TOPs vão cobrir o que está faltando.
      <br/><br/>Prazo para liberação de acesso a plataforma: 24h</p>
      <form onSubmit={handleSubmit} className="termos-form">
        <div className="form-group">
          <label>ID*</label>
          <input
            type="text"
            name="id"
            value={formData.id}
            onChange={handleChange}
            className="form-input"
          />
          {errors.id && <p className="error-text">{errors.id}</p>}
        </div>
        <div className="form-group">
          <label>E-mail*</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-input"
          />
          {errors.email && <p className="error-text">{errors.email}</p>}
        </div>
        <div className="form-group">
          <label>Nome Completo*</label>
          <input
            type="text"
            name="nomeCompleto"
            value={formData.nomeCompleto}
            onChange={handleChange}
            className="form-input"
          />
          {errors.nomeCompleto && <p className="error-text">{errors.nomeCompleto}</p>}
        </div>
        <div className="form-group checkbox-group">
          <input
            type="checkbox"
            name="aceitouTermos"
            checked={formData.aceitouTermos}
            onChange={handleChange}
            className="checkbox-input"
          />
          <label> Eu aceito os termos de uso.</label>
          {errors.aceitouTermos && <p className="error-text">{errors.aceitouTermos}</p>}
        </div>
        <button type="submit" className="submit-button" disabled={isLoading}>
          {isLoading ? 'Carregando...' : 'Aceitar'}
        </button>
        {isLoading && <div className="spinner"></div>}
      </form>
    </div>
  );
}

export default TermosDeUso;